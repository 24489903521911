<template>
  <div class="mb-3">
    <div class="subheader mb-2">Categories (Unselect to exclude)</div>

    <a
      class="list-group-item list-group-item-action cj-toggle"
      v-for="category in categories"
      :key="`cat-selector-${category.categoryid}`"
      :title="`Click to exclude`"
      href="#"
      :disabled="excludeSites.includes(category.siteid)"
      @click.capture.prevent="toggle(category)"
    >
      <div class="cover">&nbsp;</div>

      <div class="under d-flex align-items-center">
        <input
          type="checkbox"
          :class="[
            'form-check-input',
            'me-3',
            'flex-shrink-0',
            excludeSites.includes(category.siteid) ? 'disabled' : '',
            excludeSites.includes(category.siteid) ? 'indeterminate' : '',
          ]"
          :checked="!value.includes(category.categoryid)"
          :disabled="excludeSites.includes(category.siteid)"
        />

        <div class="d-flex flex-column">
          <small class="text-muted">{{ siteName(category.siteid) }}</small>
          <span>{{ category.path }}</span>
        </div>
      </div>
    </a>
  </div>
</template>

<style lang="scss">
.cj-toggle {
  .cover {
    position: absolute;
    top: 0;
    left: 0;

    z-index: 1000;

    min-width: 100%;
    min-height: 100%;

    background: transparent;
  }

  .under {
    position: relative;
    top: 0;
    left: 0;

    z-index: 1;

    width: 100%;
    height: 100%;
  }
}
</style>

<script>
export default {
  name: "CategorySelect",
  props: {
    excludeSites: Array,
    categories: Array,
    value: Array,
  },
  watch: {
    excludeSites: function () {
      this.categories.forEach((c) => {
        if (
          !this.value.includes(c.categoryid) &&
          this.excludeSites.includes(c.siteid)
        ) {
          this.value.push(c.categoryid);
        } else if (
          this.value.includes(c.categoryid) &&
          !this.excludeSites.includes(c.siteid)
        ) {
          this.value.splice(this.value.indexOf(c.categoryid), 1);
        }
      });
    },
  },
  methods: {
    siteName(siteId) {
      let sites = this.$auth.activeCompany.sites;
      let site = sites.filter((s) => s.siteID == siteId);

      if (site.length > 0) {
        return site[0].siteName;
      } else {
        return "";
      }
    },
    toggle(category) {
      if (this.excludeSites.includes(category.siteid)) {
        // no-op
        return;
      }

      if (this.value.includes(category.categoryid)) {
        this.value.splice(this.value.indexOf(category.categoryid), 1);
      } else {
        this.value.push(category.categoryid);
      }
    },
  },
};
</script>
