<template>
  <div class="home page-wrapper">
    <div class="container-fluid">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              {{ $auth.activeCompany.companyName }}
            </div>
            <h2 class="page-title">Visitor Insight Report</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-fluid">
        <div class="row mb-3" v-if="!$auth.isFeatured">
          <div class="col">
            <upgrade-to-access>
              <p>
                Upgrade to a featured listing to unlock full access to your
                visitor insights. Learn more about the specific companies
                researching for products and services in categories you are
                listed about.
              </p>
            </upgrade-to-access>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2 col-md-3 col-sm-3">
            <div class="position-sticky top-0 filter-control">
              <div class="subheader">
                Filter ({{ pagination.totalCount }} Visits)
              </div>
              <hr class="mt-2 mb-2" />
              <div class="subheader mb-2">Visit Sources</div>
              <div class="mb-3">
                <label class="form-check mb-1">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    value="profile"
                    v-model="filter.interaction_type"
                  />
                  <span class="form-check-label">Company View</span>
                </label>
                <label class="form-check mb-1">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    value="category"
                    v-model="filter.interaction_type"
                  />
                  <span class="form-check-label">Category View</span>
                </label>
              </div>
              <new-category-select
                :categories="categories"
                :excludeSites="excludedSites"
                v-model="excludedCategories"
                v-if="filter.interaction_type.includes('category')"
              ></new-category-select>
              <new-site-select
                :sites="$auth.activeCompany.sites"
                v-model="excludedSites"
              ></new-site-select>

              <date-select
                v-if="$auth.isFeatured"
                v-model="date_range"
                :suggestedRange="suggested"
              ></date-select>
              <div v-else>
                <div class="subheader">Date Range</div>
                <div class="text-center">
                  <small>
                    <router-link to="/newupgrade">
                      Upgrade for access to visitor insights from all time.
                    </router-link>
                  </small>
                </div>
                <select class="form-control mb-3" disabled>
                  <option>Last 30 days</option>
                  <option>Last 30 days</option>
                  <option>Last 30 days</option>
                </select>
              </div>

              <div>
                <div class="subheader mb-2">Export</div>
                <div class="mb-1" v-if="loadingCSV">
                  <small v-if="loadingCSV">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-spin-animation"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 6l0 -3"></path>
                      <path d="M16.25 7.75l2.15 -2.15"></path>
                      <path d="M18 12l3 0"></path>
                      <path d="M16.25 16.25l2.15 2.15"></path>
                      <path d="M12 18l0 3"></path>
                      <path d="M7.75 16.25l-2.15 2.15"></path>
                      <path d="M6 12l-3 0"></path>
                      <path d="M7.75 7.75l-2.15 -2.15"></path>
                    </svg>
                  </small>
                  <small class="text-muted"> Creating CSV export... </small>
                </div>

                <div class="mb-3" v-if="$auth.isFeatured">
                  <button
                    class="btn btn-primary w-100"
                    @click.prevent="generateCSV()"
                    :disabled="loadingCSV"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-table-export"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M11.5 20h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v7.5m-16 -3.5h16m-10 -6v16m4 -1h7m-3 -3l3 3l-3 3"
                      ></path>
                    </svg>
                    Download as CSV
                  </button>
                </div>
                <div class="mb-3" v-else>
                  <router-link class="btn btn-primary w-100" to="/newupgrade">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-table-export"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M11.5 20h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v7.5m-16 -3.5h16m-10 -6v16m4 -1h7m-3 -3l3 3l-3 3"
                      ></path>
                    </svg>
                    Upgrade to Download as CSV
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-10 col-md-9 col-sm-9">
            <div class="card">
              <div class="progress progress-sm card-progress" v-if="loadingCSV">
                <div
                  class="progress-bar progress-bar-indeterminate"
                  role="progressbar"
                ></div>
              </div>
              <div class="table-responsive">
                <div id="topOftable"></div>
                <table
                  class="table table-vcenter card-table table-striped"
                  v-if="!empty"
                >
                  <thead>
                    <tr>
                      <th>Visitor Domain</th>
                      <th>Site</th>
                      <th>Visit Source</th>
                      <th>Visitor Interests</th>
                      <th>Seniority</th>
                    </tr>
                  </thead>
                  <tbody v-if="loading">
                    <tr>
                      <td colspan="5" class="text-center text-muted">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-spin-animation me-2"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M12 6l0 -3"></path>
                          <path d="M16.25 7.75l2.15 -2.15"></path>
                          <path d="M18 12l3 0"></path>
                          <path d="M16.25 16.25l2.15 2.15"></path>
                          <path d="M12 18l0 3"></path>
                          <path d="M7.75 16.25l-2.15 2.15"></path>
                          <path d="M6 12l-3 0"></path>
                          <path d="M7.75 7.75l-2.15 -2.15"></path>
                        </svg>
                        <span v-if="loaded">Loading...</span>
                        <span v-else>Aggregating visitor information...</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="notification in notifications"
                      v-bind:key="`notification-${notification.uniqueId}`"
                    >
                      <td>
                        <div class="d-flex align-items-center">
                          <div v-if="featuredForSite(notification.siteId)">
                            {{ notification.domain }}
                            <span
                              :id="`popover_${notification.uniqueId}`"
                              v-if="notification.revenue || notification.size"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-info-circle"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <circle cx="12" cy="12" r="9"></circle>
                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                <polyline
                                  points="11 12 12 12 12 16 13 16"
                                ></polyline>
                              </svg>
                            </span>
                            <div class="text-muted small">
                              {{ niceDate(notification.visitDate) }}
                            </div>
                          </div>
                          <div v-else>
                            <span class="blur">
                              {{ domainToMasked(notification.domain) }}
                              &nbsp;
                            </span>
                            <a href="" v-if="false">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-lock-open"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <rect
                                  x="5"
                                  y="11"
                                  width="14"
                                  height="10"
                                  rx="2"
                                ></rect>
                                <circle cx="12" cy="16" r="1"></circle>
                                <path d="M8 11v-5a4 4 0 0 1 8 0"></path>
                              </svg>
                            </a>
                            <div class="text-muted small">
                              {{ niceDate(notification.visitDate) }}
                            </div>
                          </div>
                        </div>
                        <b-popover
                          :target="`popover_${notification.uniqueId}`"
                          triggers="hover"
                          placement="right"
                          v-if="
                            (notification.revenue || notification.size) &&
                            featuredForSite(notification.siteId)
                          "
                        >
                          <template #title>
                            {{ notification.domain }}
                            info
                          </template>
                          <b>Size:</b>
                          {{ notification.size }}
                          <br />
                          <b>Revenue:</b>
                          {{ notification.revenue }}
                          <span v-if="notification.industry !== ''">
                            <br />
                            <b>Industry:</b>
                            {{ splitBombora(notification.industry)[0] }}
                          </span>
                        </b-popover>
                      </td>

                      <td>
                        {{ notification.siteName }}
                      </td>

                      <td v-if="notification.item == 'category'">
                        <div>Category</div>
                        <div class="small text-muted">
                          <a
                            :href="notification.calculatedDomain"
                            target="_blank"
                          >
                            {{ notification.categoryPath }}
                          </a>
                        </div>
                      </td>
                      <td v-else>
                        <div>
                          Company -
                          {{
                            notification.item == "company"
                              ? "profile"
                              : notification.item
                          }}
                        </div>
                        <div class="small text-muted">
                          <a
                            :href="notification.calculatedDomain"
                            target="_blank"
                          >
                            Open Item
                          </a>
                        </div>
                      </td>

                      <td v-if="featuredForSite(notification.siteId)">
                        <span
                          v-b-tooltip.hover
                          :title="interests(notification.intentTopics)"
                        >
                          <span v-if="notification.intentTopics.length > 5"
                            >{{ interests(notification.intentTopics, 5) }},
                            <a href="#" @click.prevent>others</a></span
                          ><span v-else>
                            {{ interests(notification.intentTopics, 5) }}
                          </span>
                        </span>
                      </td>
                      <td v-else>
                        <div class="stacked">
                          <span class="stack-top">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-lock"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path
                                d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z"
                              ></path>
                              <path
                                d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0"
                              ></path>
                              <path d="M8 11v-4a4 4 0 1 1 8 0v4"></path>
                            </svg>

                            Unlock by
                            <router-link
                              :to="`/newupgrade?siteid=${notification.siteId}`"
                              >upgrading to featured for this
                              directory.</router-link
                            >
                          </span>

                          <span class="stack-bottom blur">
                            {{ interests(notification.intentTopics, 4) }}
                          </span>
                        </div>
                      </td>
                      <td>
                        {{ splitBombora(notification.seniority)[0] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="empty" v-else>
                  <div class="empty-img">
                    <img
                      src="@/assets/illustrations/undraw_Web_search.svg"
                      alt=""
                      height="128"
                    />
                  </div>
                  <p class="empty-title">No insights yet</p>
                  <p class="empty-subtitle text-muted">
                    Try adjusting your filters to make sure you're not filtering
                    out any insights.
                  </p>
                </div>
              </div>

              <ul
                class="pagination justify-content-center"
                v-if="loaded && !empty"
              >
                <li
                  :class="[
                    'page-item',
                    pagination.current > 1 ? '' : 'disabled',
                  ]"
                >
                  <a
                    class="page-link"
                    href="#"
                    tabindex="-1"
                    @click.prevent="changePage(-1, false)"
                  >
                    <!-- Download SVG icon from http://tabler-icons.io/i/chevron-left -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <polyline points="15 6 9 12 15 18"></polyline>
                    </svg>
                    prev
                  </a>
                </li>
                <li v-if="!pageWindow.includes(1)">
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="changePage(1, true)"
                  >
                    1 <span v-if="!pageWindow.includes(2)">...</span>
                  </a>
                </li>
                <li
                  :class="[
                    'page-item',
                    page.page == pagination.current ? 'active' : '',
                  ]"
                  v-for="page in pageWindow"
                  v-bind:key="`page-link-${page}`"
                >
                  <a
                    :class="[
                      'page-link',
                      page == pagination.current ? 'border' : '',
                      page == pagination.current ? 'disabled' : '',
                    ]"
                    :disabled="page == pagination.current"
                    href="#"
                    @click.prevent="changePage(page, true)"
                    >{{ page }}</a
                  >
                </li>
                <li v-if="!pageWindow.includes(pagination.pages)">
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="changePage(pagination.pages, true)"
                  >
                    <span v-if="!pageWindow.includes(pagination.pages - 1)"
                      >...</span
                    >
                    {{ pagination.pages }}
                  </a>
                </li>
                <li
                  :class="[
                    'page-item',
                    pagination.current == pagination.pages ? 'disabled' : '',
                  ]"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="changePage(1, false)"
                  >
                    next
                    <!-- Download SVG icon from http://tabler-icons.io/i/chevron-right -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <polyline points="9 6 15 12 9 18"></polyline>
                    </svg>
                  </a>
                </li>
              </ul>
              <div
                class="text-muted mb-1 d-flex justify-content-center"
                v-if="loaded && !empty"
              >
                <small v-if="loading">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-spin-animation"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 6l0 -3"></path>
                    <path d="M16.25 7.75l2.15 -2.15"></path>
                    <path d="M18 12l3 0"></path>
                    <path d="M16.25 16.25l2.15 2.15"></path>
                    <path d="M12 18l0 3"></path>
                    <path d="M7.75 16.25l-2.15 2.15"></path>
                    <path d="M6 12l-3 0"></path>
                    <path d="M7.75 7.75l-2.15 -2.15"></path>
                  </svg>
                </small>
                <small>
                  Page {{ pagination.current }} of
                  {{ pagination.pages }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.stacked {
  position: relative;

  .stack-top {
    position: relative;
    left: 0;
    top: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    padding: 3px;
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 1);
  }

  .stack-bottom {
    position: relative;
    left: -230px;
    top: 0;

    z-index: 0;
  }
}

.filter-control {
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.blur {
  filter: blur(5px);
  user-select: none;
  white-space: nowrap;
  text-overflow: clip;
}

.table-responsive {
  font-size: 0.875rem;
}

.icon-spin-animation {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

/* rotate 360 key for refresh btn */
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<script>
import NotificationsService from "../../services/NotificationsService";
import TrackingService from "../../services/TrackingService";
//import CategoryService from "../../services/CategoryService";
import UpsellService from "../../services/UpsellService";
import { format, parseJSON, isBefore } from "date-fns";

import NewCategorySelect from "../../components/Reports/VisitorInsight/NewCategorySelect";
import NewSiteSelect from "../../components/Reports/VisitorInsight/NewSiteSelect.vue";
import UpgradeToAccess from "../../components/Reports/UpgradeToAccess.vue";
import DateSelect from "../../components/Reports/VisitorInsight/DateSelect.vue";

let debounce = null;

export default {
  name: "VisitorInsightReport",
  components: {
    NewCategorySelect,
    NewSiteSelect,
    UpgradeToAccess,
    DateSelect,
  },
  data: () => {
    return {
      loaded: false,
      fullyLoaded: false,

      loading: false,
      loadingCSV: false,

      categories: [],

      excludedCategories: [],
      excludedSites: [],

      notifications: [],

      excludeDomains: [],
      sites_map: {},
      pagination: {
        current: 1,
        perPage: 50,
        pages: 1,
        totalCount: 0,
      },
      date_range: [],
      filter: {
        interaction_type: ["profile", "category"],
        selected_categories: [],
        selected_sites: [],
      },
      suggested: "30-days",
    };
  },
  methods: {
    categoryPath(categoryId) {
      return this.categories.filter((c) => c.categoryid == categoryId)[0].path;
    },
    featuredForSite(siteId) {
      return this.$auth.isFeaturedForSite(siteId);
    },
    domainToMasked(domain) {
      return UpsellService.domainToMasked(domain);
    },
    async generateCSV() {
      TrackingService.track("download visitor insights as csv");

      this.loadingCSV = true;

      let notifications = await this.loadNotificationList(true);
      let csv = [notifications];

      let download = document.createElement("a");
      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      let url = URL.createObjectURL(blob);
      download.href = url;
      download.setAttribute("download", "visitor_insight_export.csv");
      download.click();

      this.loadingCSV = false;
    },
    interests(interests, take) {
      return interests
        .slice(0, take)
        .filter((i) => i != "")
        .join(", ");
    },
    niceDate(date) {
      return format(parseJSON(date), "MMM. do yyyy h:mmaaa");
    },
    splitBombora(bomboraArray) {
      return bomboraArray.split("|");
    },
    updateSelectedCategories(selected) {
      this.$set(this.filter, "selected_categories", selected);
      if (
        this.filter.selected_categories.length > 0 &&
        this.filter.interaction_type.indexOf("profile") >= 0
      ) {
        this.filter.interaction_type.splice(
          this.filter.interaction_type.indexOf("profile"),
          1
        );
      } else if (
        this.filter.selected_categories.length == 0 &&
        this.filter.interaction_type.indexOf("profile") === -1
      ) {
        this.filter.interaction_type.push("profile");
      }
      this.pagination.current = 1;

      TrackingService.track("updated visitor insights report categories");
    },
    updateSelectedSites(selected) {
      this.$set(this.filter, "selected_sites", selected);
      this.pagination.current = 1;

      TrackingService.track("updated visitor insights report sites");
    },
    async changePage(number, absolute) {
      console.log(number, absolute);
      if (absolute) {
        number = Math.max(1, number);
        number = Math.min(number, this.pagination.pages);
        this.pagination.current = number;
      } else {
        this.pagination.current += number;
      }

      await this.loadNotifications();

      this.$nextTick(() => {
        let topOfTable = document.getElementById("topOftable");
        topOfTable.scrollIntoView();
      });
    },
    async loadNotificationList(asCsv = false) {
      let excludeActions = [];

      if (this.filter.interaction_type.indexOf("profile") === -1) {
        excludeActions.push("company");
      }
      if (this.filter.interaction_type.indexOf("category") === -1) {
        excludeActions.push("category");
      }

      let filter = {
        company: this.$auth.activeCompany,
        page: this.pagination.current,
        excludeCategory: this.excludedCategories,
        excludeSite: this.excludedSites,
        excludeActions: excludeActions,
      };

      if (this.date_range.length == 2) {
        filter.startDate = this.date_range[0];
        filter.endDate = this.date_range[1];
      }

      if (asCsv) {
        let nonFeaturedSites = this.$auth.activeCompany.sites
          .filter((s) => s.listingType != "featured")
          .map((s) => s.siteID);

        filter.excludeSite = [
          ...new Set(filter.excludeSite.concat(nonFeaturedSites)),
        ];

        if (this.$auth.demoActive) {
          filter.excludeSite = [];
        }

        return await NotificationsService.getVisitorInsightsCsv(filter);
      } else {
        return await NotificationsService.getVisitorInsights(filter);
      }
    },
    async loadNotifications() {
      this.loading = true;

      if (debounce) clearTimeout(debounce);

      debounce = setTimeout(async () => {
        let notifications = await this.loadNotificationList();

        this.pagination.perPage = notifications.perPage;
        this.pagination.pages = notifications.totalPages;
        this.pagination.totalCount = notifications.totalCount;

        this.notifications.splice(0);
        this.notifications.push(...notifications.visits);

        if (this.categories.length == 0) {
          this.categories.splice(0);
          this.categories.push(...notifications.categories);
        }

        this.loading = false;
      }, 250);
    },
  },
  watch: {
    "filter.interaction_type": async function () {
      this.pagination.current = 1;
      await this.loadNotifications();
    },
    excludedSites: async function () {
      this.pagination.current = 1;

      await this.loadNotifications();

      this.fullyLoaded = true;
    },
    excludedCategories: async function () {
      this.pagination.current = 1;

      if (
        this.excludedCategories.length > 0 &&
        this.excludedSites.length == 0 &&
        this.filter.interaction_type.includes("profile")
      ) {
        this.filter.interaction_type.splice(
          this.filter.interaction_type.indexOf("profile"),
          1
        );
      }

      await this.loadNotifications();

      this.fullyLoaded = true;
    },
    date_range: async function () {
      if (this.date_range.length == 2) {
        this.pagination.current = 1;

        await this.loadNotifications();

        this.fullyLoaded = true;
      }
    },
  },
  computed: {
    empty() {
      return (
        this.fullyLoaded && !this.loading && this.notifications.length == 0
      );
    },
    pageWindow() {
      let windowSize = 5;
      return Array.from(
        { length: this.pagination.pages },
        (_, i) => i + 1
      ).slice(
        Math.max(0, this.pagination.current - windowSize),
        Math.min(this.pagination.pages, this.pagination.current + windowSize)
      );
    },
    featured() {
      return this.$auth.isFeatured;
    },
    canFilterCategory() {
      return this.filter.interaction_type.indexOf("category") >= 0;
    },
  },
  async mounted() {
    await this.loadNotifications();

    this.loaded = true;
    this.fullyLoaded = true;

    if (this.$route.query.startDate) {
      let date = new Date(this.$route.query.startDate);
      let first = this.notifications.findIndex((n) => {
        return isBefore(n.notification.date, date);
      });
      if (first !== -1) {
        let page = Math.ceil(first / this.pagination.perPage);
        this.changePage(page, true, false);
      }
    }

    TrackingService.track("viewed visitor insights");
  },
};
</script>
