<template>
  <div class="mb-3">
    <div class="subheader mb-2">Sites (Unselect to exclude)</div>

    <a
      class="list-group-item list-group-item-action d-flex align-items-center cj-toggle"
      v-for="site in sites"
      :key="`cat-selector-${site.siteID}`"
      :title="`Click to exclude`"
      href="#"
      @click.prevent="toggle(site.siteID)"
    >
      <div class="cover">&nbsp;</div>
      <div class="under d-flex align-items-center">
        <input
          type="checkbox"
          class="form-check-input me-2 flex-shrink-0"
          :checked="!value.includes(site.siteID)"
          @click.prevent="toggle(site.siteID)"
        />
        {{ site.siteName }}
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "CategorySelect",
  props: {
    sites: Array,
    value: Array,
  },
  methods: {
    toggle(siteId) {
      if (this.value.includes(siteId)) {
        this.value.splice(this.value.indexOf(siteId), 1);
      } else {
        this.value.push(siteId);
      }
    },
  },
};
</script>
